<template>
  <BannerStyle1 :list="bannerList" :position="1"></BannerStyle1>
  <section class="service-introduce usually-box">
    <h3 class="small-title">全面、专业、高效的产品服务</h3>
    <div class="descript">三大核心产品线，为企业提供全方位、全生命周期的一站式数字化解决方案</div>
    <ul class="service-list">
      <li class="shadow-hover" v-for="(item, index) in productList" :key="index" @click="toPage(item.url)">
        <img :src="item.img" alt="">
        <div class="name">{{item.title}}</div>
        <p class="over-text3">{{item.desc}}</p>
        <div class="btn">了解详情</div>
      </li>
    </ul>
  </section>
  <section class="place-box usually-box">
    <h3 class="small-title stroke">支持多业务场景的数字化解决方案</h3>
    <div class="descript">布局“数字新基建”提供全行业、全场景、一站式数字化解决方案</div>
    <div class="apply-box">
      <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
        <el-radio-button :label="item.name" v-for="(item, index) in businessList" :key="index"></el-radio-button>
      </el-radio-group>
      <el-carousel height="340px" @change="changeTabs">
        <el-carousel-item v-for="item in businessList" :key="item.name">
          <div class="apply-details">
            <div class="apply-text">
              <h3>{{item.title}}</h3>
              <p class="descript">
                {{item.desc}}
              </p>
              <div class="see-details" @click="toPage(item.url)">
                了解详情
                <el-icon size="18" style="position: relative; top: 2px; margin-left: 5px;">
                  <ArrowRight />
                </el-icon>
              </div>
            </div>
            <div class="apply-img">
              <img :src="item.img" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </section>
  <section class="technological-process usually-box">
    <h3 class="small-title">服务覆盖全生命周期，精准实现您的需求</h3>
    <div class="descript">服务覆盖全生命周期，精准实现您的需求</div>
    <ul class="project-box flex-between">
      <li v-for="(item, index) in processList" :key="index">
        <div class="process-name">{{item.title}}</div>
        <ul class="project-details">
          <li v-for="(item1, index1) in item.list" :key="index1">
            {{ item1.name }}
          </li>
        </ul>
        <img class="project-img" :src="item.img" alt="">
      </li>
    </ul>
  </section>
  <brandList></brandList>
</template>
<script setup>
import { ref, reactive, computed, onMounted, onBeforeUnmount } from 'vue'
import { ArrowRight } from '@element-plus/icons'
import brandList from '@/components/Cooperate'
import { useRouter } from 'vue-router'
const route = reactive(useRouter())
function toPage(url) {
  route.push(url)
}

const bannerList = [
  {
    img: require("@/assets/home/banner.png"),
    title: '产业要素交易平台',
    desc: '以区块链技术为手段，将供应链、产业链、价值链的串联，以标准化资产为核心，将各个链条的资产整合为全栈式生产服务体系。',
    btnText: '了解详情',
    url: '/product/trade'
  }
]
const productList = [
  {
    title: '产业要素交易平台',
    img: require('@/assets/home/service1.png'),
    url: '/product/trade',
    desc: '依托海马链可信区块链技术，打造供应链、区块链、价值链多链融合业务模式的产业数字化交易平台，建立以要素为核心的上下游垂直一体化产业链布局，实现政企、银企、企业互信等方面的可靠关联。',
  },
  {
    title: '海马链BaaS平台',
    img: require('@/assets/home/service2.png'),
    url: '/product/baas',
    desc: '自主可控区块链服务平台，提供全面的区块链即服务功能，从企业和开发者角度出发，提供多种部署形式，既能灵活部署，又安全易用，构建各种业务场景更安全稳定的区块链环境，简化运维及开发流程，实现业务快速上链。',
  },
  {
    title: '智慧电商SAAS平台',
    img: require('@/assets/home/service3.png'),
    url: '/product/wisdom',
    desc: '一款针对私域流量的数字化营销工具，提供全行业全场景的电子商务体系建设解决方案，全面帮助商家解决在移动互联网时代遇到的推广获客、成交转化、客户留存、复购增长、分享裂变等问题。',
  },
]

const processList = [
  {
    title: '项目交付',
    img: require('@/assets/home/project1.png'),
    list: [
      {
        name: '立项启动',
        url: '',
      },
      {
        name: '开发实施',
        url: '',
      },
      {
        name: '培训确认',
        url: '',
      },
      {
        name: '完善上线',
        url: '',
      },
    ]
  },
  {
    title: '售后保障',
    img: require('@/assets/home/project2.png'),
    list: [
      {
        name: '远程联机维护',
        url: '',
      },
      {
        name: '7*24客服团队',
        url: '',
      },
      {
        name: '客户培训教育',
        url: '',
      },
      {
        name: '专属活动推广',
        url: '',
      },
    ]
  },
  {
    title: '运营管理',
    img: require('@/assets/home/project3.png'),
    list: [
      {
        name: '用户画像分析',
        url: '',
      },
      {
        name: '业务监测',
        url: '',
      },
      {
        name: '业务统计分析',
        url: '',
      },
      {
        name: '业务趋势分析',
        url: '',
      },
    ]
  },
  {
    title: '规范把控',
    img: require('@/assets/home/project4.png'),
    list: [
      {
        name: '实名认证机制',
        url: '',
      },
      {
        name: '咨询服务规范',
        url: '',
      },
      {
        name: '数据保密制度',
        url: '',
      },
      {
        name: '内部质控体系',
        url: '',
      },
    ]
  },

]

const businessList = reactive([
  {
    id: 1,
    name: "供应链金融",
    title: "供应链金融",
    url: "/solve/home?id=1",
    desc: "链接企业资产端及金融机构资金端，降低小微企业融资成本，为金融机构提供更多投资场景，助力普惠金融。",
    img: require("@/assets/home/apply1.png"),
  },
  {
    id: 2,
    name: "应收账款管理",
    title: "应收账款管理",
    url: "/solve/home?id=2",
    desc: "构建企业和金融机构的联盟链，通过企业交易信息的加密共享，降低金融机构面临的信用风险。",
    img: require("@/assets/home/apply2.png"),
  },
  {
    id: 3,
    name: "溯源",
    title: "溯源",
    url: "/solve/home?id=5",
    desc: "利用IOT、区块链、移动端等技术实现商品溯源数字化，促成商品供应链各环节的信息可信共享，提高正品的真实可信，提升产业供应链整体收益。",
    img: require("@/assets/home/apply3.png"),
  },
  {
    id: 4,
    name: "政务民生",
    title: "政务民生",
    url: "/solve/home?id=3",
    desc: "依托区块链不可篡改、可追溯等特性，助力各级政府机构及民生保障部门信息共享和保护，使得政务服务实现“一次妥”乃至“一次都不跑”。",
    img: require("@/assets/home/apply4.png"),
  },
  {
    id: 5,
    name: "工业数据可信解决方案",
    url: "/solve/home?id=4",
    title: "工业数据可信解决方案",
    desc: "以区块链技术为基础，联合产业交易平台、国内外银行、供应链服务商等建立数字化产业交易和金融服务平台。",
    img: require("@/assets/home/apply5.png"),
  },
  {
    id: 6,
    name: "电子商务",
    title: "电子商务",
    url: "/post/contact",
    desc: "利用区块链将资金、凭证等资产数字化，打通企业经营中资产效率低下环节，提升信用能力的同时提高金融机构的风险控制，为企业提供更好的融资贷款。",
    img: require("@/assets/home/apply6.png"),
  },
  {
    id: 6,
    name: "其他定制化服务",
    title: "其他定制化服务",
    url: "/post/contact",
    desc: "利用区块链将资金、凭证等资产数字化，打通企业经营中资产效率低下环节，提升信用能力的同时提高金融机构的风险控制，为企业提供更好的融资贷款。",
    img: require("@/assets/home/apply7.png"),
  },
])

const tabPosition = ref('供应链金融')

let nowTabs = computed(() => {
  const item = businessList.find(el => el.name === tabPosition.value)
  return item
})


function changeTabs(index) {
  tabPosition.value = businessList[index].name
}
// const animBus = reactive({
//   anim() {

//   }
// })
// onMounted(() => {
//   let nowBusIndex = 0;
//   setTimeout(() => {
//     tabPosition.value = 
//   }, 2000)
// })
// function changeTabs(item) {
//   nowTabs = item
// }
</script>
<style lang='scss' scoped>
.banner-img {
  width: 100%;
}
::v-deep .el-radio-group {
  width: 100%;
}
::v-deep .el-radio-button {
  flex: 1;
}
::v-deep .el-radio-button__inner {
  font-size: 16px;
  padding: 10px 23px;
  width: 100%;
}
.service-introduce {
  padding-bottom: 80px;
  background: url("../../assets/home/back1.png") center center no-repeat;
  background-size: cover;
}
.small-title {
  padding: 90px 0 23px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.descript {
  font-size: 18px;
  color: #808080;
  text-align: center;
}
.service-list {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  > li {
    position: relative;
    box-sizing: border-box;
    padding: 40px 25px 80px;
    width: 32%;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      transform: translateY(-20px);
      transition: transform 1s;
    }
    > img {
      height: 72px;
    }
    .name {
      margin: 40px 0 25px;
      font-size: 22px;
      color: #202020;
    }
    > p {
      margin-bottom: 40px;
      font-size: 14px;
      text-align: left;
      color: #808080;
    }
    .btn {
      position: absolute;
      left: 50%;
      bottom: 32px;
      margin-left: -86px;
      padding: 8px 22px;
      width: 128px;
      font-size: 16px;
      color: #0766e1;
      border: 1px solid #0766e1;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

.place-box {
  padding-bottom: 70px;
  background: url("../../assets/home/resolve-back.png") fixed no-repeat;
  background-size: cover;
  .small-title {
    color: #fff;
  }
  .descript {
    color: rgba(255, 255, 255, 0.8);
  }
  .apply-box {
    position: relative;
    margin-top: 55px;
    padding: 50px 94px;
    width: 1200px;
    background-color: #fff;
  }
  .apply-details {
    display: flex;
    position: relative;
    padding-top: 20px;
    z-index: 10;
    .apply-text {
      > h3 {
        padding: 32px 0;
        font-size: 30px;
        color: #202020;
      }
      .descript {
        text-align: left;
        font-size: 18px;
        line-height: 1.6em;
        color: #808080;
      }
      .see-details {
        display: flex;
        align-items: center;
        margin-top: 50px;
        font-size: 18px;
        color: #0766e1;
        cursor: pointer;
      }
    }
    .apply-img {
      flex-shrink: 0;
      margin-left: 93px;
      > img {
        width: 415px;
        height: 260px;
      }
    }
  }
}

.technological-process {
  padding-bottom: 84px;
  background-color: #fff;
  .project-box {
    margin-top: 56px;
    > li {
      position: relative;
      box-sizing: border-box;
      padding: 48px 39px 63px;
      width: 225px;
      // background-color: #eee;
      border: 1px solid #ccc;
      &:hover {
        box-shadow: 0 20px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 15%);
      }
      &::after {
        position: absolute;
        top: 35%;
        font-size: 70px;
        color: #1e90ff;
        content: ">";
      }
      &:first-child {
        transform: scale(0.9);
        &::after {
          right: -90px;
        }
      }
      &:nth-child(2) {
        &::after {
          right: -70px;
        }
      }
      &:nth-child(3) {
        transform: scale(1.1);
        &::after {
          right: -55px;
        }
      }
      &:nth-child(4) {
        transform: scale(1.2);
        &::after {
          content: "";
        }
      }
      cursor: pointer;
      .process-name {
        margin-bottom: 17px;
        font-size: 20px;
        color: #202020;
      }
      .project-img {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 85px;
      }
    }
  }
  .project-details {
    > li {
      margin-bottom: 13px;
      font-size: 16px;
      color: #808080;
      &:hover {
        color: #0766e1;
      }
    }
  }
}
::v-deep .el-radio-group {
  flex: 1;
}
</style>