<template>
  <section class="cooperate-box usually-box">
    <h3 class="small-title">携手与全球合作伙伴共建数字生态</h3>
    <div class="descript">2018年以来已累计为100+企业提供技术支持，涵盖制造、家居、教育、旅游等行业</div>
    <div class="brand-box">
      <ul class="brand-list">
        <li v-for="(item, index) in brandList" :key="index">
          <img :src="item.logo" alt="" />
        </li>
      </ul>
    </div>
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import { getBrandSelect } from '@/api/publish'
const brandList = ref([])
//#region 
// [

//   {
//     img: require('@/assets/home/brand1.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand2.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand3.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand4.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand5.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand6.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand7.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand8.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand9.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand10.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand11.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand12.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand13.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand14.png'),
//     name: '',
//   },
//   {
//     img: require('@/assets/home/brand15.png'),
//     name: '',
//   },

// ]
//#endregion

getBrandSelect({
  page: 1,
  size: 100,
}).then(res => {
  brandList.value = res.data.data
})
</script>
<style lang = 'scss' scoped>
.small-title {
  padding: 90px 0 23px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.descript {
  font-size: 18px;
  color: #808080;
  text-align: center;
}
/* .brand-box {
  width: 120%;
} */
.brand-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 56px;
  padding-bottom: 30px;
  $Mright: 25px;
  > li {
    margin-bottom: 31px;
    width: 20%;
    text-align: left;
    > img {
      width: 216px;
      height: 75px;
      /* height: 47px; */
      &:hover {
        box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.5);
        transform: translateY(-8px);
        transition: all 0.3s;
      }
    }
  }
}
</style >